/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {PowerMetricsColor} from '../../../components/globalStyles';
import ChargingProgress
  from '../../../components/chargingProgress/ChargingProgress';
import Timer from '../../../components/timer/Timer';
import {LoadingSm} from '../../../components/loaders/localLoading/LocalLoading';
import ModalContacts from '../../contactsPage/modal/ModalContacts';
import {Button} from '@ui';

import {getClientId} from '../../../hookServices/clientId';
import {Card} from "../../../shared/ui";
import {Box} from "@mui/material";
import {Chart} from "../fullInfo/Chart";

export default function OnlineStation({
  station,
  stationStatus,
  periodKwh,
  carKwtKmRatio,
  kWhCharged,
  costPerKwt,
  kWtPower,
  chargeStatus,
  onlineStatus,
  timer,
  isZero,
  lastJob,
  invoiceId,
}) {
  const [over, setOver] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();
  const checkInvoiceId = localStorage.getItem('invoiceId') === invoiceId;
  const urlProtocol = window.location.protocol;
  const urlHost = window.location.host;
  const [hideSpinner, setHideSpinner] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function callToSupport() {
    if (!onlineStatus) {
      setIsOpen(true);
    }
  }

  function stopProcessCharging() {
    setHideSpinner(false)
    fetch(`${urlProtocol}//${urlHost}:8080/api/device/v2/stop`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({clientId: getClientId()}),
    })
    .then(response => {
      if (!response.ok && response.status !== 404) {
        throw new Error('http error!');
      }

      return response.json();
    })
    // .then(({data}) => console.log(data))
    .catch(e => {
      throw new Error(e);
    });
  }

  function showPowerInfo() {
    return <>
      <Box sx={{m: "10% 7%"}}>
        <Card
            variant="invert"
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginBlock: "10px"
            }}
        >
          <Col id="onlineStationKmChargedBox" xs lg={6}
               className="text-center m-3">
            <PowerMetricsColor id="onlineStationKmChargedTitle"
                               className="mb-0 textTitle">
              {t('power')}
            </PowerMetricsColor>
            <p id="onlineStationKmChargedInfo" className="textInfo text">
              {onlineStatus === false ? (
                  <>{t('onlineStatus')}</>
              ) : (
                  <>
                    {kWtPower.toFixed(2)} {t('powerWt')}
                  </>
              )}
            </p>
          </Col>

          <Row>
            <Chart
                power={{
                  currentKw: !onlineStatus ? 0 : Number(lastJob?.powerWt)
                      / 1000,
                  maxKw: station?.maxPowerWt > 0 ? station.maxPowerWt / 1000
                      : CHART_MAX_KW,
                }}
                voltage={!onlineStatus ? 0 : Number(
                    Math.round(lastJob?.voltage))}
            />
          </Row>
          <Row id="fullInfoKwtMetricsBox" className="justify-content-center">
            <Col id="fullInfoKwtChargedBox" className="text-center mb-2">
              <PowerMetricsColor id="fullInfoKwtChargedTitle"
                                 className="mb-0 textTitle ">
                {t('charging')}
              </PowerMetricsColor>
              <p id="fullInfoKwtChargedInfo"
                 className="textInfo voltTitle text">
                {chargeStatus}
                <br/>
                {t('wt')}
              </p>
            </Col>
            <Col id="fullInfoKwtPowerBox" xs lg={6} className="text-center">
              <PowerMetricsColor id="fullInfoKwtPowerTitle"
                                 className="mb-0 textTitle ">
                {t('payedForKwh')}
                {/*  todo rename css*/}
              </PowerMetricsColor>
              <p id="fullInfoKwtPowerInfo" className="textInfo voltTitle text">
                {periodKwh}
                <br/>
                {t('btns.startKwt')}
              </p>
            </Col>
          </Row>
        </Card>
      </Box>
    </>;
  }

  function showHumanInfo() {
    return <>
      <Row id="onlineStationKmMetricsBox"
           className="justify-content-center infoContainer">
        <Col id="onlineStationKmChargedBox" xs lg={6} className="text-center">
          <PowerMetricsColor id="onlineStationKmChargedTitle"
                             className="mb-0 textTitle">
            {t('charging')}
          </PowerMetricsColor>
          {/* charged  km */}
          <p id="onlineStationKmChargedInfo" className="textInfo text">
            {isZero ? 0 : ((kWhCharged * 1000) / carKwtKmRatio).toFixed(2)}
            {t('km')}
            {/* {type !== "SECONDS" && (
              <span
                id="onlineStationKmChargedInfo"
                style={{ fontSize: "14px" }}
              >
                {amountMoney}
                {t("currency")}
              </span>
            )} */}
          </p>
        </Col>
        <Col id="onlineStationKmPowerBox" xs lg={6} className="text-center">
          <PowerMetricsColor id="onlineStationKmPowerTitle"
                             className="mb-0 textTitle">
            {t('spent')}
          </PowerMetricsColor>
          <p id="onlineStationKmPowerInfo" className="textInfo text">
            {(kWhCharged * costPerKwt / 100).toFixed(2)} {t('currency')}
          </p>
        </Col>
      </Row>
      <ChargingProgress
          status={lastJob}
          online={onlineStatus}
          widthContainer={'120px'}
          marginContainer={'0 auto'}
          widthDot={'20px'}
          heightDot={`20px`}
      />
      {lastJob.soc != null ? (
      // {true ? (
              <p className="textTitle text-center">
                {lastJob.soc} %
              </p>
          )
          : (timer !== null ? (
                      <Timer
                          hours={Number(timer[0])}
                          minutes={Number(timer[1])}
                          seconds={Number(timer[2])}
                          fontSize={'calc(1rem + 1vw)'}
                          margin={'0'}
                          over={over}
                          setOver={setOver}
                          onlineStatus={onlineStatus}
                      />)
                  : (
                      <LoadingSm/>
                  )
          )}
    </>;
  }

  return (
      <div id="onlineStationBox">
        {showHumanInfo()}
        {showPowerInfo()}
        {
            !onlineStatus && (
                <Button
                    onClick={callToSupport}
                    className="mainBtn buttonForOfflineStation w-100">
                  <span className="mainBtnText">{t('callUs')}</span>
                </Button>
            )
        }

        {
            hideSpinner && checkInvoiceId && onlineStatus && (
                <Row className={'m-2'}>
                  <Button onClick={stopProcessCharging}
                          className="mainBtn buttonForOfflineStation w-100">
                    <span className="mainBtnText">{t('stopButton')}</span>
                  </Button>
                </Row>
            )
        }

        {
            !hideSpinner && checkInvoiceId && onlineStatus && (
                <LoadingSm/>
            )
        }

        <ModalContacts modalOpen={isOpen} setModalOpen={setIsOpen}/>
      </div>
  )
      ;
}
